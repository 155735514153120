<template>
  <div class="py-3 min-w-0 w-full">
    <div class="display-1 d-flex">
      <span>Sopimuspohjat</span>
    </div>
    <v-card class="mt-2">
      <v-card-title>
        <v-spacer></v-spacer>
        <router-link style="text-decoration: none" to="/contract-model/create">
          <v-btn v-if="isAuthorized('contract', 'create')" color="info" class="mr-1"
            >Uusi sopimuspohja</v-btn
          >
        </router-link>
      </v-card-title>

      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="models"
          :footer-props="globalValues.footerProps"
          :items-per-page="getDefaultTableRows()"
        >
          <template #[`item.name`]="{ item }">
            <span class="font-weight-bold">{{ item.name }}</span>
          </template>

          <template #[`item.modelType`]="{ item }">
            <span>{{ getModelType(item.modelType) }}</span>
          </template>

          <!-- Actions -->
          <template #[`item.actions`]="{ item }">
            <div class="action-grid-container">
              <v-icon
                v-if="isAuthorized('contract', 'edit')"
                title="Muokkaa"
                small
                @click="editItem(item)"
                >mdi-pencil</v-icon
              >
              <v-icon
                v-if="isAuthorized('contract', 'delete')"
                title="Poista"
                small
                @click="deleteItem(item)"
                >mdi-delete</v-icon
              >
            </div>
          </template>
          <template #no-data>
            <h2>Ei sopimuspohjia</h2>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import axiosMethods from "../../mixins/axios";
import mixins from "../../mixins/mixins";
import globalValues from "@/configs/globalValues";

export default {
  title: "Sopimuspohjat",

  mixins: [mixins],

  data: () => ({
    models: [],
    headers: [
      { text: "Nimi", value: "name" },
      { text: "Tyyppi", value: "modelType" },
      {
        text: "Toiminnot",
        value: "actions",
        sortable: false,
      },
    ],
    globalValues,
  }),
  async created() {
    try {
      // Get all models
      let res = await axiosMethods.get(`/api/v1/contract-model/getAll?search=all`);
      this.models = res.data.data;
    } catch (err) {
      this.showPopup(err, "error");
    }
  },
  methods: {
    editItem(item) {
      this.$router.push("/contract-model/edit/" + item._id);
    },

    async deleteItem(item) {
      if (confirm("Haluatko varmasti poistaa sopimuspohjan: " + item.name)) {
        try {
          await axiosMethods.delete("/api/v1/contract-model/delete/" + item._id);

          let models = this.models.filter(function (el) {
            return el._id != item._id;
          });

          this.models = models;

          this.showPopup("Sopimuspohja poistettu", "success");
        } catch (err) {
          this.showPopup(err, "error");
        }
      }
    },
  },
};
</script>

<style scoped></style>
